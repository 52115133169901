<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：项目管理 > <b>特殊下游协议</b></span>
            <div class="flex-center" @click="backPage">
                <i class="iconfont iconjiantou-copy-copy"></i>返回
            </div>
        </div>
        <div class="sub-merchants-info">
            <span>特殊下游协议</span>
            <div class="sub-merchants-container">
                <div class="input-item">
                    <div class="input-item-title">会议名称</div>
                    <el-select
                        ref="pName"
                        v-model="pName"
                        :placeholder="`请选择${$getTitleByType('会议名称',pid.cid)}`"
                            @change="proChange"
                            filterable>
                        <el-option
                                v-for="(item, index) in projectOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="index">
                        </el-option>
                    </el-select>
                </div>
                <div class="input-item">
                    <div class="input-item-title">所属学会</div>
                    <el-select
                            v-model="society"
                            :placeholder="`请选择所属学会（请选择${$getTitleByType('会议名称',pid.cid)}）`"
                            @visible-change="clickProject"
                    >
                        <el-option
                                v-for="item in societyOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="input-item">
                    <div class="input-item-title">{{$getTitleByType('会议日期',pid.cid)}}</div>
                    <el-input
                        v-model="pid.time"
                        :placeholder="`请选择${$getTitleByType('会议名称',pid.cid)}`"
                        disabled
                    ></el-input>
                </div>
<!--                <div class="input-item">-->
<!--                    <div class="input-item-title">会议形式</div>-->
<!--                    <el-input v-model="pid.meet_form_str" :placeholder="`请选择${$getTitleByType('会议名称',pid.cid)}`" disabled></el-input>-->
<!--                </div>-->
                <div class="input-item">
                    <div class="input-item-title">{{$getTitleByType('大会主席',pid.cid)}}</div>
                    <el-input
                        v-model="pid.chairman"
                        :placeholder="`请选择${$getTitleByType('会议名称',pid.cid)}`"
                        disabled
                    ></el-input>
                </div>
                <div class="input-item">
                    <div class="input-item-title">付款类型</div>
                    <el-radio-group v-model="payment_type">
                        <el-radio :label="1">{{$getTitleByType('会议基金',pid.cid)}}</el-radio>
                        <el-radio :label="2">专项基金</el-radio>
                    </el-radio-group>
                </div>
                <div class="input-item">
                    <div class="input-item-title">委托项目</div>
                    <el-input v-model="payment" placeholder="请输入委托项目"></el-input>
                </div>
                <div class="input-item">
                    <div class="input-item-title">供应商名称（全称）</div>
                    <el-input
                            v-model="corporate_name"
                            placeholder="请输入供应商名称（全称）"
                    ></el-input>
                </div>
                <div class="input-item">
                    <div class="input-item-title">联系人</div>
                    <el-autocomplete
                            class="inline-input"
                            v-model="contacts"
                            :fetch-suggestions="querySearchUser"
                            placeholder="请输入联系人"
                            :trigger-on-focus="false"
                            value-key="contacts"
                    ></el-autocomplete>
                </div>
                <div class="input-item">
                    <div class="input-item-title">合同金额</div>
                    <el-input
                            v-model="amounts"
                            placeholder="请输入合同金额"
                            type="number"
                            @mousewheel.native.prevent
                            @input="setAmount(0)"
                    ></el-input>
                    <div class="money">万元</div>
                </div>
                <!--<div class="input-item">-->
                    <!--<div class="input-item-title">会议金额</div>-->
                    <!--<el-input-->
                            <!--v-model="meeting_decimal"-->
                            <!--placeholder="请输入会议金额"-->
                            <!--type="number"-->
                            <!--@mousewheel.native.prevent-->
                            <!--@input="setAmount(2)"-->
                    <!--&gt;</el-input>-->
                    <!--<div class="money">万元</div>-->
                <!--</div>-->
                <!--<div class="input-item">-->
                    <!--<div class="input-item-title">自用金额</div>-->
                    <!--<el-input-->
                            <!--v-model="self_amount"-->
                            <!--placeholder="请输入自用金额"-->
                            <!--type="number"-->
                            <!--@mousewheel.native.prevent-->
                            <!--@input="setAmount(1)"-->
                    <!--&gt;</el-input>-->
                    <!--<div class="money">万元</div>-->
                <!--</div>-->
                <div class="input-item">
                    <div class="input-item-title">加盖何种印章</div>
                    <el-select
                            v-model="seal_id"
                            placeholder="请选择加盖何种印章"
                            disabled
                    >
                        <el-option
                                v-for="item in sealOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>

                <div class="input-item">
                    <div class="input-item-desc">备注</div>
                    <el-input
                            type="textarea"
                            resize="none"
                            :rows="5"
                            v-model="remark"
                            placeholder="请输入"
                    ></el-input>
                </div>
                <div class="input-item">
                    <div class="input-item-desc">上传附件</div>
                    <div>
                        <div v-if="files.length > 0" class="upfile-list">
                            <ul v-for="(file, index) in files" :key="index">
                                <li class="flex-center between-box">
                                    <a
                                            class="ovHide"
                                            :href="file.filePath | filePath"
                                            target="_blank"
                                    >{{ file.fileName }}</a
                                    >
                                    <i class="el-icon-delete" @click="closeFile(index)"></i>
                                </li>
                            </ul>
                        </div>
                        <el-upload
                                :action="uploadPath"
                                :show-file-list="false"
                                :multiple="true"
                                :data="QiniuData"
                                :on-error="uploadError"
                                :on-success="uploadSuccess"
                                :before-upload="beforeAvatarUpload"
                        >
                            <el-button size="small" type="primary">点击上传文件</el-button>
                            <!--<div slot="tip" class="el-upload__tip">只能上传PDF文件</div>-->
                        </el-upload>
                    </div>
                </div>
                <div class="input-item">
                    <div class="input-item-title">合同状态</div>
                    <el-radio-group v-model="contract">
                        <el-radio :label="1">已回</el-radio>
                        <el-radio :label="0">未回</el-radio>
                    </el-radio-group>
                    <el-date-picker
                            v-if="contract == 1"
                            v-model="contract_time"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            type="datetime"
                            placeholder="请输入合同已回时间"
                    >
                    </el-date-picker>
                </div>
                <approvalList
                        :typeClassify="6"
                        :isNoHave="!id"
                        :copyMembers="copyMembers"
                        @setMember="setMember"
                        @setApproval="setApproval"
                ></approvalList>
                <el-button type="primary" @click="subApproval" :loading="requestState" :disabled="requestState" >提交</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import ElInput from "../../../node_modules/element-ui/packages/input/src/input.vue";
    import ElButton from "../../../node_modules/element-ui/packages/button/src/button.vue";
    import approvalList from "../../components/approvalList.vue";
    import {genUpToken,qiniuaddr,uploadPath} from "../../tools/CryptoJS";
    import {webUrl} from "../../tools";
    export default {
        components: {
            ElButton,
            ElInput,
            approvalList,
        },
        name: "contract",
        data() {
            return {
                id: "",
                sign_code: "",

                society: "",
                societyOptions: [],
                pid: "",
                pName: "",
                projectOptions: [],
                corporate_name: "",
                contacts: "",
                amounts: "",

                self_amount: "",
                meeting_decimal: "",
                payment_type: 1,
                contract: 0,
                contract_time: "",
                payment: "",

                remark: "",
                seal_id: 2,
                sealOptions: this.$tools.sealTypes(),
                shenpi: "",
                copy: "",
                QiniuData: {
                    fname: "", //  文件名
                    key: "", //文件名字处理
                    token: "", //七牛云token
                },
                qiniuaddr:qiniuaddr, // 七牛云的图片外链地址 你的七牛云里配置有
                uploadPath:uploadPath, // 七牛云的图片外链地址 你的七牛云里配置有
                files: [],
                users: [],
                approvalMembers: [],
                copyMembers: [],
                requestState:false
            };
        },
        created() {
            let token = genUpToken();
            this.QiniuData.token = token;
            this.id = this.$route.query.id ? this.$route.query.id : '';
            this.contract_time = this.$tools.ZHDate(new Date(),6)
            this.$emit("setIndex", [4, 22]);
            this.init();
        },
        methods: {
            setAmount(type) {
                if (type === 0) {
                    this.amounts = this.amounts.replace(/[^0-9 .]/g, "");
                    this.self_amount = "0";
                    this.meeting_decimal = "0";
                } else if (type === 1) {
                    this.self_amount = this.self_amount.replace(/[^0-9 .]/g, "");
                    if (this.amounts) {
                        if (this.self_amount) {
                            this.meeting_decimal = parseFloat((
                                Number(this.amounts).toFixed(6) - Number(this.self_amount).toFixed(6)).toFixed(6));
                        } else {
                            this.meeting_decimal = parseFloat(Number(this.amounts).toFixed(6));
                        }
                    } else {
                        if (this.self_amount && this.meeting_decimal) {
                            this.amounts = parseFloat((
                                Number(this.meeting_decimal).toFixed(6) + Number(this.self_amount).toFixed(6)).toFixed(6));
                        }
                    }
                } else {
                    this.meeting_decimal = this.meeting_decimal.replace(/[^0-9 .{1}]/g, "");
                    if (this.amounts) {
                        if (this.meeting_decimal) {
                            this.self_amount = parseFloat((
                                Number(this.amounts).toFixed(6) - Number(this.meeting_decimal).toFixed(6)).toFixed(6));
                        } else {
                            this.self_amount = parseFloat(Number(this.amounts).toFixed(6));
                        }
                    } else {
                        if (this.self_amount && this.meeting_decimal) {
                            this.amounts = parseFloat((
                                Number(this.meeting_decimal).toFixed(6) + Number(this.self_amount).toFixed(6)).toFixed(6));
                        }
                    }
                }
            },
            querySearchUser(queryString, cb) {
                const restaurants = this.users;
                const results = queryString
                    ? restaurants.filter(this.createUserFilter(queryString))
                    : restaurants;
                // 调用 callback 返回建议列表的数据
                cb(results);
            },
            createUserFilter(queryString) {
                return (restaurant) => {
                    return (
                        restaurant.contacts
                            .toLowerCase()
                            .indexOf(queryString.toLowerCase()) !== -1
                    );
                };
            },
            subApproval() {
                if (!this.pid) {
                    this.$message.error(this.$refs.pName.placeholder);
                    return;
                }

                if (!this.society) {
                    this.$message.error("请选择所属学会");
                    return;
                }

                if (!this.payment) {
                    this.$message.error("请输入委托项目");
                    return;
                }
                if (!this.corporate_name) {
                    this.$message.error("请输入供应商名称");
                    return;
                }
                if (!this.contacts) {
                    this.$message.error("请输入联系人");
                    return;
                }
                if (!this.amounts) {
                    this.$message.error("请输入合同金额");
                    return;
                }
                if (this.self_amount === "") {
                    this.$message.error("请输入专项金额");
                    return;
                }
                if (this.meeting_decimal === "") {
                    this.$message.error("请输入会议金额");
                    return;
                }
                if (!this.seal_id) {
                    this.$message.error("请选择加盖何种印章");
                    return;
                }
                //                if (!this.remark) {
                //                    this.$message.error("请输入备注")
                //                    return
                //                }
                if (this.files.length <= 0) {
                    this.$message.error("请上传附件");
                    return;
                }
                if (this.contract === 1 && !this.contract_time) {
                    this.$message.error("请输入合同已回时间");
                    return;
                }
                if (this.shenpi.indexOf("-1") !== -1) {
                    this.$message.error("请完善审批人员");
                    return;
                }
                this.requestState = true
                //添加
                this.$api.apiContent
                    .teshuhetong({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        sid: this.society,
                        pid: this.pid.id,
                        sign_code: this.sign_code,
                        corporate_name: this.corporate_name,
                        contacts: this.contacts,
                        contract_time: this.contract === 1 ? this.contract_time : "",
                        payment: this.payment,
                        payment_type: this.payment_type,
                        amount: this.amounts,
                        self_amount: this.self_amount,
                        meeting_decimal: this.meeting_decimal,
                        contract: this.contract,
                        remark: this.remark,
                        files: JSON.stringify(this.files),
                        shenpi: this.shenpi,
                        copy: this.copy,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                sid: this.society,
                                pid: this.pid.id,
                                sign_code: this.sign_code,
                                corporate_name: this.corporate_name,
                                contacts: this.contacts,
                                contract_time: this.contract === 1 ? this.contract_time : "",
                                payment: this.payment,
                                payment_type: this.payment_type,
                                amount: this.amounts,
                                self_amount: this.self_amount,
                                meeting_decimal: this.meeting_decimal,
                                contract: this.contract,
                                remark: this.remark,
                                files: JSON.stringify(this.files),
                                shenpi: this.shenpi,
                                copy: this.copy,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        this.requestState = false
                        this.$message.success(res.msg);
                        this.$router.push("send");
                    })
                    .catch((e) => {
                        this.requestState = false
                        this.$message.error(e.msg);
                    });
            },
            proChange(index) {
                let value = this.projectOptions[index];
                value.time =
                    this.$tools.ZHDate(new Date(value.start_time * 1000)) +
                    " 至 " +
                    this.$tools.ZHDate(new Date(value.end_time * 1000));
                this.pName = value.name;
                this.pid = value;
                this.society=''
                this.getSociety();
            },
            clickProject(view) {
                if (!this.pid && view) {
                    this.$message.error("请选择会议名称");
                }
            },
            getSociety() {
                this.$api.apiContent
                    .getSocietyFromPro({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        pid: this.pid.id,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                pid: this.pid.id,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        this.societyOptions = res.data;
                        if (res.data.length === 1 && !this.society) this.society = res.data[0].id;
                    })
                    .catch(() => {
                        this.societyOptions = [];
                    });
            },
            getAmountXie() {
                this.$api.apiContent
                    .getAmountXie({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        pid: this.pid.id,
                        sid: this.society,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                pid: this.pid.id,
                                sid: this.society,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        this.amount_meet =res.data.amount_meet
                        this.amount_self =res.data.amount_self
                        this.if_update =res.data.if_update === 1
                    })
                    .catch(() => {
                        this.amount_meet ="0"
                        this.amount_self ="0"
                        this.if_update =false
                    });
            },
            //  页面加载时请求
            init() {
                this.$api.apiContent
                    .getProject({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        res.data.map(item=>{
                            item.name = this.$tools.ZHDate(new Date(item.start_time * 1000)) + " 至 "
                                + this.$tools.ZHDate(new Date(item.end_time * 1000)) +'/'+ item.chairman +'/'+ item. name
                        })
                        this.projectOptions = res.data;
                    })
                    .catch(() => {
                        this.projectOptions = [];
                    });
                this.$api.apiContent
                    .getUserList({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        this.users = res.data;
                    })
                    .catch(() => {
                        this.users = [];
                    });
                if (this.id) {
                    this.$api.apiContent
                        .getteshuHetong({
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.id,
                            sign: this.$tools.SHA256(
                                {
                                    token: this.$store.state.user.token,
                                    timestamp: this.$tools.requestTime(),
                                    id: this.id,
                                },
                                this.$store.state.user.key
                            ),
                        })
                        .then((res) => {
//            this.approvalMembers = res.data.shenpi;
//            this.shenpi = res.data.shenpi
//              .map((item) => {
//                return item.user_id;
//              })
//              .join(",");
                            this.copyMembers = res.data.copy;
                            this.copy = res.data.copy
                                .map((item) => {
                                    return item.user_id;
                                })
                                .join(",");
                            this.society = res.data.sid;
                            this.sign_code = res.data.sign_code;
                            res.data.project.time =
                                this.$tools.ZHDate(
                                    new Date(res.data.project.start_time * 1000)) +
                                " 至 " +
                                this.$tools.ZHDate(new Date(res.data.project.end_time * 1000));
                            res.data.project.id = res.data.pid;
                            this.pid = res.data.project;
                            this.pName = this.$tools.ZHDate(new Date(res.data.project.start_time * 1000)) + " 至 "
                                + this.$tools.ZHDate(new Date(res.data.project.end_time * 1000)) + '/' + res.data.project.chairman + '/' +  res.data.project.name;
                            this.name = res.data.name;
                            this.corporate_name = res.data.corporate_name;
                            this.payment = res.data.payment;
                            this.contacts = res.data.contacts;
                            this.contract_time = res.data.contract_time;
                            this.payment_type = res.data.payment_type;
                            this.amounts = parseFloat(Number(res.data.amount).toFixed(6));
                            this.self_amount = res.data.self_amount;
                            this.meeting_decimal = res.data.meeting_decimal;
                            this.contract = res.data.contract;
                            this.remark = res.data.remark;
                            this.files = res.data.files;
                            this.getSociety();
                        })
                        .catch(() => {
                        });
                }
            },
            //  上传成功
            uploadSuccess(response, file, fileList) {
                console.log(file);
                console.log(fileList);
                this.files.push({
                    filePath: `${this.qiniuaddr}/${response.key}`,
                    //在这里你就可以获取到上传到七牛的外链URL了
                    fileName: file.name,
                });

                this.loading.close();
            },
            closeFile(position) {
                this.$confirm("此操作将删除该文件, 是否继续?", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                })
                    .then(() => {
                        this.files.splice(position, 1);
                    })
                    .catch();
            },
            //  上传失败
            uploadError() {
                this.loading.close();
                this.$message({
                    message: "上传出错，请重试！",
                    type: "error",
                    center: true,
                });
            },
            beforeAvatarUpload(file) {
                // const isJPG = file.type === 'application/pdf';
                // if (!isJPG) {
                //   this.$message.error('只能上传PDF格式!');
                //   return
                // }
                //这里的key给加上了时间戳，目的是为了防止上传冲突
                this.QiniuData.fname = file.name;
                this.QiniuData.key = `${new Date().getTime()}${file.name}`;
                this.loading = this.$loading({
                    lock: true,
                    text: "上传中，请稍后",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
            },
            backPage() {
                this.$tools.goBack();
            },
            //审批人
            setApproval(members) {
                this.shenpi = members;
            },
            //抄送人
            setMember(members) {
                this.copy = members;
            },
        },
        filters: {
            filePath(url) {
               return webUrl(url)
            },
        },
        watch: {
        },
    };
</script>

<style scoped lang="scss">
    .page-box {
        .page-title {
            height: 40px;
            margin-bottom: 10px;

            span {
                font-size: 18px;
                line-height: 36px;
                color: #999999;

                b {
                    color: #333;
                    font-weight: normal;
                }
            }

            div {
                color: #3b77e7;
                font-size: 20px;
                cursor: pointer;

                i {
                    display: block;
                    width: 24px;
                    height: 24px;
                    text-align: center;
                    line-height: 24px;
                    margin-right: 5px;
                    border-radius: 100%;
                    color: #fff;
                    font-size: 14px;
                    background-color: #3b77e7;
                }
            }
        }
        .sub-merchants-info {
            width: 100%;
            background-color: #ffffff;
            border-radius: 6px;
            padding: 40px 25px;
            min-height: 800px;
            span {
                color: #000000;
                font-size: 24px;
            }
            .sub-merchants-container {
                width: 60%;
                display: flex;
                flex-direction: column;
                margin-top: 20px;
                .tip-red{
                    color: red;
                    font-weight: bold;
                    margin-left: 23%;
                    margin-bottom: 20px;
                }
                .input-item {
                    display: flex;
                    min-height: 40px;
                    margin-bottom: 20px;
                    flex-direction: row;
                    align-items: center;
                    overflow: hidden;
                    .upfile-list {
                        width: 400px;
                        li {
                            height: 31px;
                            border-bottom: 1px dashed #ccc;

                            a {
                                display: block;
                                width: 350px;
                                height: 30px;
                                line-height: 30px;
                                color: #3b77e7;
                            }

                            .el-icon-delete {
                                font-size: 16px;
                                cursor: pointer;

                                &:hover {
                                    color: #3b77e7;
                                }
                            }
                        }
                    }
                    .money {
                        flex-shrink: 0;
                        margin-left: 10px;
                    }
                    .money-count {
                        flex-shrink: 0;
                        margin-left: 10px;
                        font-size: 16px;
                        &.red{
                            color: red!important;
                            span{
                                color: red!important;
                            }
                        }
                    }

                    .input-item-title {
                        width: 20%;
                        text-align: center;
                        font-size: 16px;
                        margin-right: 20px;
                        color: #000000;
                        flex-shrink: 0;
                    }
                    .input-item-desc {
                        width: 20%;
                        align-self: flex-start;
                        margin-top: 10px;
                        margin-right: 20px;
                        text-align: center;
                        font-size: 16px;
                        color: #000000;
                        flex-shrink: 0;
                    }
                    .el-input,
                    .el-autocomplete,
                    .el-select,
                    .el-textarea,
                    .el-radio-group,
                    .el-date-editor,
                    .el-upload {
                        flex-grow: 1;
                        resize: none;
                    }
                }
                > .el-button {
                    margin-top: 30px;
                    align-self: center;
                    width: 40%;
                }
            }
        }
    }
</style>
